export const StatusCode = {
	new: 'New',
	pending: 'Pending_Approval',
	approved: 'Approved',
	rejected: 'Rejected'
};

export const OpenSubmittedCode = {
	open: 'Open',
	submitted: 'Submitted',
	archived: 'Archived'
};
