import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

  private messageSource = new BehaviorSubject({key:"DEFAULT_KEY",body:{}});
  subscribeMessage = this.messageSource.asObservable();

  constructor() { }

  sendMessage(message: {key:string,body:any}) {
    this.messageSource.next(message)
  }

}